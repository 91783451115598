@use '~/src/sass/abstracts/utils' as *;
@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/structure';

.only-text {
	color: colors.$grey-400;
}

.only-text--classic {
	.btn {
		margin-top: 4rem;
	}
}

.only-text__chapo {
	& * {
		@extend %font-md;

		font-weight: 700;
	}
}

.only-text__blockquote-wrapper {
	padding: 5rem 3rem;
	font-weight: 400;
	background-color: colors.$grey-100;
	border-radius: 20px;

	i {
		margin-bottom: 1rem;
		font-size: 2.2rem;
	}

	blockquote {
		margin-bottom: 3rem !important;

		p {
			@extend %font-lg;

			font-style: italic;
		}
	}

	figcaption {
		@extend %font-sm;

		font-weight: 900;
		text-transform: uppercase;
	}
}

@media screen and (min-width: structure.$tablet-landscape) {
	.only-text__paragraph {
		&--50-50 {
			display: grid;
			grid-template-columns: repeat(2, minmax(1rem, 1fr));
			column-gap: 3rem;
		}
	}

	.only-text__blockquote-wrapper {
		padding: 5.5rem 6rem;
	}
}
